/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Flex } from "@theme-ui/components"
import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"

import { mediaQueryList, renderLineBreak, findBySlug } from "../helpers"

import { NavigationContext } from "./layout"
import PageLink from "./page-link"

import plantPrefabLogo from "../images/PP-Logo-Full-Color-TM.svg"
import chevron from "../images/icon-arrow.svg"

const dropdownContainerSelector = "header-dropdown-container"

const styleList = {
  heading: {
    fontSize: 4,
    mb: 1,
  },
  listItem: {
    color: "text",
    fontWeight: [500, 500, 500, "initial"],
    cursor: ["pointer", "pointer", "pointer", "default"],
    fontSize: [4, 4, 4, 2],
    mb: 0,
    mr: [0, 0, 0, 20],
    span: {
      px: [null, null, null, "14px"],
      py: "20px",
    },
    ":last-child": {
      span: {
        pr: [null, null, null, 0],
      },
    },
    position: "relative",
    [`${mediaQueryList()[2]}`]: {
      [`& > .${dropdownContainerSelector}`]: { display: "none" },
      "&:hover": {
        [`& > .${dropdownContainerSelector}`]: { display: "block", zIndex: 3 },
        [`& > .${dropdownContainerSelector}.reset`]: { display: "none" },
      },
      ":last-child": { mr: 0 },
    },
  },
  listItemText: {
    ":hover": {
      color: "primary",
    },
    display: "block",
    fontFamily: "heading",
    px: [5, 5, 5, 0],
    py: [3, 3, 3, 0],
  },
  linkItem: {
    color: "text",
    textDecoration: "none",
  },
  listContainer: {
    listStyle: "none",
    m: 0,
    minWidth: 300,
    p: 0,
  },
  placeholderImage: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    border: "1px solid #eee",
    borderRadius: "100%",
    flexShrink: 0,
    height: 50,
    width: 50,
  },
}

const HeaderContentCard = ({
  asset,
  body,
  heading,
  link,
  linkText,
  type,
  image,
}) => {
  const componentType = type || "primary"
  const componentStyle = {
    componentWrapper: {
      primary: {
        alignItems: "center",
        display: "flex",
        mb: 3,
      },
    },
    asset: {
      primary: {
        ...styleList.placeholderImage,
        backgroundImage: image ? `url(${image.src})` : null,
      },
      secondary: {
        ...styleList.placeholderImage,
        backgroundImage: image ? `url(${image.src})` : null,
        borderRadius: 0,
        height: 120,
        width: "100%",
      },
    },
    contentWrapper: {
      primary: { ml: [0, 0, 0, 3] },
      secondary: { mt: 4 },
    },
    body: {
      primary: {
        fontSize: 2,
        color: ["white", "white", "white", "#c0b2a5"],
        fontWeight: [500, 500, 500, "initial"],
      },
      secondary: { fontSize: 2, mb: 2 },
    },
  }

  const componentContent = (
    <React.Fragment>
      {asset && (
        <div
          sx={{
            ...componentStyle.asset[componentType],
            display: ["none", "none", "none", "block"],
          }}
        />
      )}
      <div sx={componentStyle.contentWrapper[componentType]}>
        <h4
          sx={{
            fontSize: [4, 4, 4, 3],
            fontWeight: [500, 500, 500, "heading"],
            mb: 1,
            whiteSpace: "nowrap",
            color: ["white", "white", "white", "text"],
          }}
        >
          {heading}
        </h4>
        <p sx={componentStyle.body[componentType]}>{renderLineBreak(body)}</p>
        {componentType === "secondary" && link && linkText && (
          <PageLink
            customStyle={{ color: "primary" }}
            link={link}
            text={`${linkText} >`}
            type="internal"
          />
        )}
      </div>
    </React.Fragment>
  )

  return componentType === "primary" && link ? (
    <li
      sx={{
        mb: [30, 30, 30, 15],
        ":last-child": { mb: [10, 10, 10, 0] },
        ":first-child": { mt: [10, 10, 10, 0] },
      }}
    >
      <Link
        sx={{
          color: "inherit",
          textDecoration: "none",
          ...componentStyle.componentWrapper[componentType],
        }}
        to={link}
      >
        {componentContent}
      </Link>
    </li>
  ) : (
    <li sx={componentStyle.componentWrapper[componentType]}>
      {componentContent}
    </li>
  )
}
const HeaderDropdownContainer = ({
  children,
  customStyle,
  resetHover,
  onClick,
}) => (
  <div
    className={`${dropdownContainerSelector} ${resetHover ? "reset" : ""}`}
    onClick={onClick}
    role="link"
    onKeyDown={event => event.keyCode === 13 && onClick()}
    tabIndex={0}
    sx={{
      backgroundColor: theme => [
        theme.colors.primary,
        theme.colors.primary,
        theme.colors.primary,
        "transparent",
      ],
      fontFamily: "body",
      [`${mediaQueryList()[2]}`]: {
        left: "50%",
        position: "absolute",
        mt: "30px",
        pt: "21px",
        top: 27,
        transform: "translateX(-50%)",
        zIndex: 1,
      },
      ...customStyle,
    }}
  >
    <Flex
      sx={{
        [`${mediaQueryList()[2]}`]: {
          bg: "white",
          borderRadius: 5,
          boxShadow: "0px 0px 27px 0px rgba(0, 0, 0, 0.26)",
        },
        px: [5, 5, 5, 4, 4, 4],
        py: [3, 3, 3, 4, 4, 4],
      }}
    >
      {children}
    </Flex>
  </div>
)
const HeaderFeaturedProject = ({ name, location, image }) => (
  <div
    sx={{
      backgroundImage: `url(${image})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: 130,
      position: "relative",
      width: [200, 200, 200, 200, 200, 250],
    }}
  >
    <div
      sx={{
        color: "white",
        textShadow: "1px 1px 7px rgba(0,0,0,1)",
        fontSize: 1,
        padding: 2,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 175,
      }}
    >
      <p sx={{ lineHeight: 1, mb: 1 }}>{name}</p>
      <p sx={{ lineHeight: 1 }}>{location}</p>
    </div>
  </div>
)
const HeaderLinkList = ({ customStyle, heading, linkList, type }) => {
  const componentType = type || "primary"
  const componentStyle = {
    wrapper: {
      primary: {
        ...styleList.listContainer,
        borderLeft: "1px solid #e4e4e4",
        minWidth: 200,
        pl: 4,
        py: 0,
      },
      tertiary: {
        ...styleList.listContainer,
        minWidth: 135,
        ":first-child": { mt: [15, 15, 15, 0] },
      },
    },
    heading: {
      primary: { fontWeight: "heading" },
      secondary: {
        color: "#999999",
        fontSize: 1,
        fontWeight: "body",
        lineHeight: 1,
        textTransform: "uppercase",
      },
    },
    link: {
      primary: { fontSize: 1 },
      tertiary: {
        fontFamily: "heading",
        fontSize: [4, 4, 4, 3],
        fontWeight: ["body", "body", "body", "heading"],
        color: ["white", "white", "white", "text"],
        ":last-child": { mb: [30, 30, 30, 0] },
        display: "block",
      },
    },
  }

  return (
    <ul
      sx={{
        ...(componentType === "secondary"
          ? {
              ...componentStyle.wrapper.primary,
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
            }
          : componentStyle.wrapper[componentType]),
        ...customStyle,
      }}
    >
      {heading && (
        <li
          sx={{
            fontSize: 1,
            mb: 1,
            ...componentStyle.heading[componentType],
          }}
        >
          {heading}
        </li>
      )}
      {linkList.map(linkItem => (
        <li key={linkItem.id}>
          <Link
            sx={{
              color: "primary",
              fontFamily: "heading",
              fontSize: 2,
              textDecoration: "none",
              ...componentStyle.link[componentType],
            }}
            to={linkItem.link}
          >
            {linkItem.text || linkItem.component}
          </Link>
        </li>
      ))}
    </ul>
  )
}
const HeaderNavigationItem = ({
  isActionable,
  link,
  text,
  setResetHover,
  onClick,
  hardLink= null
}) => (
  <React.Fragment>
    {!isActionable && text && (
      <span
        role="link"
        tabIndex={0}
        sx={styleList.listItemText}
        onMouseOver={() => {
          setResetHover && setResetHover(false)
        }}
        onFocus={() => setResetHover && setResetHover(false)}
      >
        {text}
      </span>
    )}
    {hardLink && isActionable && link && text &&(
      <a
        sx={{ ...styleList.linkItem }}
        href={link}
        onMouseOver={() => {
          setResetHover && setResetHover(false)
        }}
        onFocus={() => setResetHover && setResetHover(false)}
        onClick={onClick}
      >
        <span
          sx={{
            ...styleList.listItemText,
          }}
        >
          {text}
        </span>
      </a>
    )}
    {!hardLink && isActionable && link && text && (
      <Link
        sx={{ ...styleList.linkItem }}
        to={link}
        onMouseOver={() => {
          setResetHover && setResetHover(false)
        }}
        onFocus={() => setResetHover && setResetHover(false)}
        onClick={onClick}
      >
        <span
          sx={{
            ...styleList.listItemText,
          }}
        >
          {text}
        </span>
      </Link>
    )}
  </React.Fragment>
)
const HeaderMenuItem = ({ isAccordion, heading, panel }) => {
  return isAccordion ? (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionItemState>
            {state => (
              <span
                sx={{
                  position: "absolute",
                  top: "0px",
                  left: "20px",
                }}
              >
                <img
                  src={chevron}
                  sx={{
                    transform: state?.expanded
                      ? "rotate(90deg)"
                      : "rotate(-90deg)",
                  }}
                  width="12"
                  height="24"
                />
              </span>
            )}
          </AccordionItemState>
          {heading}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>{panel}</AccordionItemPanel>
    </AccordionItem>
  ) : (
    <React.Fragment>
      {heading}
      {panel}
    </React.Fragment>
  )
}
const HeaderNavigation = ({ isSmallResolution, isOpen, setIsOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPage(slug: { eq: "navbar" }) {
        ...PageData
        ...BladePatternData
      }
      contentfulSequence(slug: { eq: "sequence-projects" }) {
        orderItems {
          ... on ContentfulProject {
            id
            slug
            projectImages {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_withWebp
              }
              id
              title
            }
            projectTitle {
              projectTitle
            }
            projectLocation {
              projectLocation
            }
          }
        }
      }

      modelsImage: file(relativePath: { eq: "nav-model-image-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      projectsImage: file(relativePath: { eq: "nav-project-image-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const modelsBlade = findBySlug({
    list: data.contentfulPage.bladePatternList,
    slug: "navbar-models-module",
  })
  const galleryBlade = findBySlug({
    list: data.contentfulPage.bladePatternList,
    slug: "navbar-gallery-module",
  })
  const projects = data.contentfulSequence.orderItems
    .slice(0, 2)
    .map(project => {
      return {
        link: `projects/${project.slug}`,
        id: project.id,
        component: (
          <HeaderFeaturedProject
            name={project.projectTitle.projectTitle}
            location={project.projectLocation.projectLocation}
            image={project.projectImages[0].fluid.src}
          />
        ),
      }
    })

  const linkList = {
    company: [
      // {
      //   id: "f6def2fc-5ba4-5e0e-abbb-2d5e60d03ea0",
      //   link: "/faq",
      //   text: "FAQ",
      // },
      {
        id: "4c560196-931c-461b-b2e6-1f481e09909e",
        link: "/about",
        text: "About",
      },
      {
        id: "f6def2fc-5b34-5e0e-abbb-2d5e60d03ea0",
        link: "/blog",
        text: "Blog",
      },
      {
        id: "6df3a2c6-a24f-471a-8be2-51e09aa2ee21",
        link: "/jobs",
        text: "Careers",
      },
      // {
      //   id: "DesignPartners",
      //   link: "/architects",
      //   text: "Design Partners",
      // },
      {
        id: "DesignStudio",
        link: "/plant-design-studio",
        text: "Design Studio",
      },
      // {
      //   id: "f6def2fc-5ba4-5e0e-abbb-2d5e60d03ea0",
      //   link: "/faq",
      //   text: "FAQ",
      // },
      {
        id: "InTheNews",
        link: "/in-the-news",
        text: "In the Media",
      },
      {
        id: "PressCenter",
        link: "/press-center",
        text: "Press Center",
      },
      /*
      {
        id: "fc21c05f-29ae-4302-b447-6f6576145cc0",
        link: "/newsroom",
        text: "News",
      },
      {
        id: "cdd13338-ac73-4710-ba86-1cc47b35a283",
        link: "/newsroom",
        text: "Events",
      },
      */

      // {
      //   id: "c306c1ee-715b-462e-a050-242504aa321e",
      //   link: "/tours-and-events",
      //   text: "Tours & Events",
      // },
    ],
    featuredProjects: projects,
    models: [
      {
        id: "0b0356c9-ba4d-434e-b4e4-67db8b9ba112",
        link: "/models/#?beds=0-1",
        text: "ADUs  (Detached Units)",
      },
      {
        id: "7563908a-61d2-4fba-8f1d-8d4b0ffae08d",
        link: "/models/#?beds=1-2",
        text: "1-2 Bedroom Homes",
      },
      {
        id: "b82e123c-7b68-4015-9dfd-6f0fb7f9d742",
        link: "/models/#?beds=3-4",
        text: "3-4 Bedroom Homes",
      },
      {
        id: "c9ac17fb-e4ea-4610-ba7a-a7fde781ac63",
        link: "/models/#?beds=5-0",
        text: "5+ Bedroom Homes",
      },
      {
        id: "models-multi-family",
        link: "/models/#?multifamily=true",
        text: "Multi-Family Homes",
      },
    ],
    // workingWithUs: [
    //   // {
    //   //   id: "a3c3eb9c-f6fe-47bf-b27a-15f56580f8e6",
    //   //   link: "/homebuyers",
    //   //   text: "Homebuyers",
    //   // },
    //   {
    //     id: "c020fb08-f3f9-45f4-b992-04c173b14b09",
    //     link: "/developers",
    //     text: "Developers",
    //   },
    //   {
    //     id: "1b9473a3-121f-4c5a-80a2-d45b8d9bee08",
    //     link: "/architects-and-designers",
    //     text: "Architects",
    //   },
    // ],
    galleryModels: [
      {
        id: "0b0356c9-ba4d-434e-b4e4-67db8b9ba112",
        link: "/projects/#?projectType=adu",
        text: "ADUs  (Detached Units)",
      },
      {
        id: "7563908a-61d2-4fba-8f1d-8d4b0ffae08d",
        link: "/projects/#?projectType=single-family",
        text: "Single-Family Homes",
      },
      {
        id: "b82e123c-7b68-4015-9dfd-6f0fb7f9d742",
        link: "/projects/#?projectType=multi-family",
        text: "Multi-Family Homes",
      },
      {
        id: "c9ac17fb-e4ea-4610-ba7a-a7fde781ac63",
        link: "/projects/#?projectType=development",
        text: "Developments",
      },
      {
        id: "c9ac17fb-e4ea-4610-ba7a-a7fde781ac63",
        link: "/projects/#?projectType=hospitality",
        text: "Hospitality",
      },
      {
        id: "models-multi-family",
        link: "/projects/#?projectType=student-housing",
        text: "Student Housing",
      },
    ],
  }
  const [resetHover, setResetHover] = React.useState(false)
  const onClick = () => {
    setResetHover(true)
    setIsOpen(!isOpen)
  }
  const navigationContent = customStyle => (
    <ul
      sx={{
        borderTop: [
          "1px solid #eee",
          "1px solid #eee",
          "1px solid #eee",
          "none",
        ],
        display:
          isSmallResolution && isOpen
            ? ["flex", "flex", "flex", "none"]
            : ["none", "none", "none", "flex"],
        flexDirection: ["column", "column", "column", "row"],
        listStyle: "none",
        m: 0,
        marginLeft: [0, 0, 0, "auto"],
        width: ["100%", "100%", "100%", "auto"],
        ...customStyle,
      }}
    >
      {/* <li sx={styleList.listItem}>
        <HeaderNavigationItem
          setResetHover={setResetHover}
          isActionable
          link="/advantages"
          text="Advantages"
          onClick={onClick}
        />
      </li> */}
      <li sx={styleList.listItem}>
        <HeaderNavigationItem
          setResetHover={setResetHover}
          isActionable
          link="/capabilities"
          text="Capabilities"
          onClick={onClick}
        />
      </li>
      {/* <li
        sx={{
          ...styleList.listItem,
          order: [1, 1, 1, "initial"],
        }}
      >
        <HeaderMenuItem
          isAccordion={isSmallResolution}
          heading={
            <HeaderNavigationItem
              setResetHover={setResetHover}
              text="Services"
            />
          }
          panel={
            <HeaderDropdownContainer resetHover={resetHover} onClick={onClick}>
              <ul
                sx={{
                  ...styleList.listContainer,
                  pr: [0, 0, 0, 4],
                }}
              >
                
                <HeaderContentCard
                  body="Architectural design services"
                  heading="Design"
                  link= "/plant-design-studio"
                  // link="/custom-design"
                />
                <HeaderContentCard
                  body="Efficient fabrication of any design"
                  heading="Fabrication"
                  link="/custom-construction"
                />
                
              </ul>
              <HeaderLinkList
                customStyle={{
                  display: ["none", "none", "none", "block"],
                }}
                heading="Working With Us"
                linkList={linkList.workingWithUs}
                type="secondary"
              />
            </HeaderDropdownContainer>
          }
        />
      </li> */}
      {/* <li sx={styleList.listItem}>
        <HeaderNavigationItem
          isActionable={isSmallResolution}
          setResetHover={setResetHover}
          link="/models"
          text="LivingHomes"
          onClick={onClick}
        />
        <HeaderDropdownContainer
          resetHover={resetHover}
          customStyle={{ display: ["none", "none", "none", "block"] }}
          onClick={onClick}
        >
          <ul
            sx={{
              ...styleList.listContainer,
              pr: 4,
            }}
          >
            <HeaderContentCard
              asset
              body={modelsBlade.description.description}
              heading={modelsBlade.title}
              image={{ src: modelsBlade.desktopImage.file.url }}
              link={modelsBlade.ctaDestination}
              linkText={modelsBlade.ctaTitle}
              type="secondary"
            />
          </ul>
          <HeaderLinkList
            heading="Browse By"
            linkList={linkList.models}
            type="secondary"
          />
        </HeaderDropdownContainer>
      </li> */}
      {/* <li sx={styleList.listItem}>
        <HeaderNavigationItem
          isActionable={isSmallResolution}
          text="Gallery"
          link="/projects"
          setResetHover={setResetHover}
          onClick={onClick}
        />
        <HeaderDropdownContainer
          customStyle={{
            display: ["none", "none", "none", "block"],
            left: ["auto", "auto", "0 !important"],
            transform: ["none", "none", "translateX(-55%) !important"],
          }}
          resetHover={resetHover}
          onClick={onClick}
        >
          <ul
            sx={{
              ...styleList.listContainer,
              pr: 4,
            }}
          >
            <HeaderContentCard
              asset
              body={galleryBlade.description.description}
              heading={galleryBlade.title}
              image={{ src: galleryBlade.desktopImage.file.url }}
              link={galleryBlade.ctaDestination}
              linkText={galleryBlade.ctaTitle}
              type="secondary"
            />
          </ul>
          <HeaderLinkList
            heading="Browse By"
            linkList={linkList.galleryModels}
            type="secondary"
          />
        </HeaderDropdownContainer>
      </li> */}
      <li sx={styleList.listItem}>
        <HeaderNavigationItem
          setResetHover={setResetHover}
          isActionable
          link="/projects"
          text="Portfolio"
          onClick={onClick}
        />
      </li>
      {/* <li
        sx={{
          ...styleList.listItem,
          display: ["block", "block", "block", "none"],
          order: [2, 2, 2, "initial"],
        }}
      >
        <HeaderMenuItem
          isAccordion={isSmallResolution}
          heading={
            <HeaderNavigationItem
              setResetHover={setResetHover}
              text="Working With Us"
            />
          }
          panel={
            <HeaderDropdownContainer onClick={onClick}>
              <HeaderLinkList
                linkList={linkList.workingWithUs}
                type="tertiary"
              />
            </HeaderDropdownContainer>
          }
        />
      </li> */}
      <li
        sx={{
          ...styleList.listItem,
          order: [3, 3, 3, "initial"],
        }}
      >
        <HeaderMenuItem
          isAccordion={isSmallResolution}
          heading={
            <HeaderNavigationItem
              setResetHover={setResetHover}
              text="Company"
            />
          }
          panel={
            <HeaderDropdownContainer resetHover={resetHover} onClick={onClick}>
              <HeaderLinkList linkList={linkList.company} type="tertiary" />
            </HeaderDropdownContainer>
          }
        />
      </li>
      <li sx={styleList.listItem}>
        <HeaderNavigationItem
          setResetHover={setResetHover}
          isActionable
          link="/models"
          text="LivingHomes"
          hardLink={true}
          onClick={onClick}
        />
      </li>
      <li sx={styleList.listItem}>
        <HeaderNavigationItem
          setResetHover={setResetHover}
          isActionable
          link="/contact"
          text="Contact"
          onClick={onClick}
        />
      </li>
    </ul>
  )

  return isSmallResolution ? (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      sx={{ display: ["block", "block", "block", "none"], width: "100%" }}
    >
      {navigationContent()}
    </Accordion>
  ) : (
    navigationContent({ display: ["none", "none", "none", "flex"] })
  )
}

const Header = ({ showNavFooter }) => {
  const headerStyle = {
    bg: "white",
    backgroundImage: "linear-gradient(180deg, #fefefe, #ebebeb)",
  }
  const [navigationOpen, setNavigationState] = React.useContext(
    NavigationContext
  )
  return (
    <header
      sx={
        navigationOpen
          ? {
              ...headerStyle,
              height: ["100%", "100%", "100%", "auto"],
              overflowY: ["auto", "auto", "auto", "visible"],
              position: ["fixed", "fixed", "fixed", "static"],
              width: ["100%", "100%", "100%", "auto"],
              zIndex: [99999, 99999, 99999, 1],
            }
          : headerStyle
      }
    >
      <Flex
        sx={{
          alignItems: ["flex-start", "flex-start", "flex-start", "center"],
          flexDirection: ["column", "column", "column", "row"],
          maxWidth: 1352,
          mx: "auto",
          px: [0, 0, 0, !showNavFooter ? 30 : 20],
        }}
      >
        <div
          sx={{
            alignItems: "center",
            display: "flex",
            height: theme => theme.sizes.header.height,
            px: [20, 20, 20, 0],
            width: ["100%", "100%", "100%", "auto"],
          }}
        >
          <a
            sx={{
              display: "block",
              fontSize: "0px",
              lineHeight: 1,
            }}
            href="/"
          >
            <img
              sx={{
                height: 46,
                width: 106,
                m: 0,
              }}
              src={plantPrefabLogo}
              alt="Plant Prefab Logo"
            />
          </a>
          {showNavFooter && (
            <div
              sx={{
                display: ["block", "block", "block", "none"],
                ml: "auto",
              }}
            >
              <button
                sx={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  display: "block",
                  p: 0,
                }}
                type="button"
                onClick={() => setNavigationState(!navigationOpen)}
              >
                {!navigationOpen && (
                  <React.Fragment>
                    <div
                      sx={{
                        backgroundColor: "text",
                        height: 3,
                        mb: 1,
                        width: 25,
                      }}
                    />
                    <div
                      sx={{
                        backgroundColor: "text",
                        height: 3,
                        mb: 1,
                        width: 25,
                      }}
                    />
                    <div
                      sx={{
                        backgroundColor: "text",
                        height: 3,
                        width: 25,
                      }}
                    />
                  </React.Fragment>
                )}
                {navigationOpen && (
                  <React.Fragment>
                    <div
                      sx={{
                        backgroundColor: "text",
                        height: 3,
                        width: 25,
                        transform: "rotate(45deg)",
                      }}
                    />
                    <div
                      sx={{
                        backgroundColor: "text",
                        height: 3,
                        position: "relative",
                        top: "-3px",
                        transform: "rotate(-45deg)",
                        width: 25,
                      }}
                    />
                  </React.Fragment>
                )}
              </button>
            </div>
          )}
        </div>
        {showNavFooter && (
          <>
            {" "}
            <HeaderNavigation
              isOpen={navigationOpen}
              setIsOpen={setNavigationState}
            />
            <HeaderNavigation
              isOpen={navigationOpen}
              setIsOpen={setNavigationState}
              isSmallResolution
            />
          </>
        )}
      </Flex>
    </header>
  )
}

export default Header
